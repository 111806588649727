<template>
  <div class="content-calculator">
    <div class="calculator">
      <div class="subtitle-intelligential">
        <span class="content-icon">
          <i class="fas fa-dollar-sign" />
        </span>
        ¿Cuánto dinero necesitas?
      </div>
      <div class="input-content">
        <input
          class="input-range"
          type="range"
          :step="rangeAmount"
          :min="minAmount"
          :max="maxAmount"
          v-model="amount"
          @change="getSimulate()"
        />

        <div class="input-round">
          <div class="content-info-amount">
            <input
              name="amount"
              type="text"
              class="inside-div text-bold-int"
              v-model="amount"
              @keypress="decimalNumbers($event)"
              @change="getSimulate('amount')"
            />
            <p class="text-input">MXN</p>
          </div>
        </div>
      </div>

      <div class="content-amount">
        <p>{{ formatMoney(minAmount) }}</p>
        <p>{{ formatMoney(maxAmount) }}</p>
      </div>

      <div class="subtitle-intelligential">
        <span class="content-icon">
          <i class="far fa-calendar-alt" />
        </span>
        Elige el plazo de pago
      </div>
      <div class="input-content">
        <input
          class="input-range"
          type="range"
          :max="maxTerm"
          :min="1"
          v-model="term"
          @change="getSimulate()"
        />

        <div class="input-round">
          <div class="content-info-amount">
            <input
              class="inside-div text-bold-int"
              type="text"
              v-model="term"
              @keypress="onlyNumbers($event)"
              @change="getSimulate('term')"
            />
            <p class="text-input">
              {{ term === 1 ? 'Mes' : 'Meses'}}
            </p>
          </div>
        </div>
      </div>

      <div class="content-amount">
        <p>{{ minTerm }} mes</p>
        <p>{{ maxTerm }} meses</p>
      </div>
    </div>

    <div class="terms-content">
      <h5 class="subtitle-intelligential">Selecciona la frecuencia con la que deseas realizar tus pagos</h5>

      <div class="container-buttons-int">

        <!--div
          class="button-intelligential"
          :class="{ 'is-blue': periodicity === 'W' }"
          @click="setPeriodicity('W')"
        >
          <p class="text-big">Semanales</p>
        </div>

        <div
          class="button-intelligential"
          :class="{ 'is-blue': periodicity === 'BW' }"
          @click="setPeriodicity('BW')"
        >
          <p class="text-big">Quincenales</p>
        </div-->

        <div
          class="button-intelligential"
          :class="{ 'is-blue': periodicity === 'M' }"
          @click="setPeriodicity('M')"
        >
          <p class="text-big">Mensuales</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formats from '@/mixins/formats'
import validators from '@/mixins/validators'

export default {
  mixins: [formats, validators],
  props: {
    maxAmount: {
      type: Number,
      default: null
    },
    minAmount: {
      type: Number,
      default: null
    },
    maxTerm: {
      type: Number,
      default: null
    },
    minTerm: {
      type: Number,
      default: null
    },
    interest: {
      type: Number,
      default: null
    },
    rangeAmount: {
      type: Number,
      default: null
    },
    showCat: {
      type: String,
      default: ''
    },
    productType: {
      type: String,
      default: ''
    },
    openingcommission: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      amount: this.minAmount,
      term: 1,
      periodicity: 'M',
      moneyFormat: {
        prefix: ''
      },
    }
  },
  methods: {
    setPeriodicity (value) {
      this.periodicity = value
      this.getSimulate()
    },
    getSimulate (type) {
      let body = {}

      if (type === 'amount' || type === 'term') this.getCorrectValue(type)

      if (this.productType === 'bullet') {
        body = {
          interest: this.interest,
          term: this.term,
          amount: this.amount,
          commission: this.openingcommission
        }
      } else {
        body = {
          interest: this.interest,
          periodicity: this.periodicity,
          term: this.term,
          amount: this.amount,
          commission: this.openingcommission
        }
      }

      this.$emit('hdlOnChange', body)
    },
    getCorrectValue (type) {
      if (type === 'amount') {
        if (this.amount > this.maxAmount) {
          this.amount = this.maxAmount
        } else if (this.amount < this.minAmount) {
          this.amount = this.minAmount
        }
      } else if (type === 'term') {
        if (this.term > this.maxTerm) {
          this.term = this.maxTerm
        } else if (this.term < this.minTerm) {
          this.term = this.minTerm
        }
      }
    }
  }
}
</script>
