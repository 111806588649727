
export default {
  methods: {
    onlyNumbers (evt) {
      evt = !evt ? window.event : evt
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    decimalNumbers (evt) {
      evt = !evt ? window.event : evt
      var charCode = evt.which ? evt.which : evt.keyCode
      const dots = (evt.target.value.match(new RegExp(/\./, 'g')) || []).length
      if ((charCode < 48 || charCode > 57) && (charCode !== 46 || dots > 0)) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
