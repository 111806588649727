import * as api from '@/utils/simulator'

export const frequencyStore = {
    namespaced: true,
    state: () => ({
      item: {
        amount: 0,
        interest: 0,
        term: 0,
        totalPayments: 0
      }
    }),

    mutations: {
      setItem(state, item) {
        state.item = item
      }
    },
    actions: {
      async simulateBulletPayment ({ commit }, {interest, amount, term, commission }) {
        try {
          const interestRate = interest / 100
          const commissionAmount = parseFloat(amount) * parseInt(commission) / 100
          const amountFinal = amount * (interestRate / 12) * 1.16
          const amountFinalWithoutIva = amount * (interestRate / 12)
          const lastPayment = Number(amount) + Number(amountFinal)
          const lastPaymentWithoutIva = Number(amount) + Number(amountFinalWithoutIva)
          let payments = [(amount - commissionAmount) * -1]
          if (term > 1) {
            let samePayments = Array(term - 1).fill(amountFinalWithoutIva)
            payments = [...payments, ...samePayments, lastPaymentWithoutIva]
            const cat = await api.getCAT(payments, interestRate / 100) * 12
            commit('setItem', {
              term,
              interest,
              totalPayments: term - 1,
              lastPayment: lastPayment,
              amount: amountFinal,
              requestedAmount: amount,
              commissionAmount: commissionAmount,
              cat
            })
          } else {
            payments.push(lastPaymentWithoutIva)
            const cat = await api.getCAT(payments, interestRate / 100) * 12
            commit('setItem', {
              term,
              interest,
              totalPayments: 1,
              amount: lastPayment,
              requestedAmount: amount,
              commissionAmount: commissionAmount,
              cat
            })
          }
          return {}
        } catch (error) {
          return {}
        }
      },
      async getSimulate ({ commit }, body) {
          try {
            const item = await api.getSimulate(body)
            const commissionAmount = parseFloat(body.body.amount) * parseInt(body.body.commission) / 100
            let payments = [(body.body.amount - commissionAmount) * -1]
            const samePayments = Array(Number(item.term)).fill(Number(item.amount))
            payments = [...payments, ...samePayments]
            const cat = await api.getCAT(payments, item.interest / 100) * 12
            commit('setItem', { ...item, cat, commissionAmount })
            return item
          } catch (error) {
           return {}
          }
      }
    }
}