<template>
  <div>
    <div class="card-intelligential">
      <div class="info-amount">
        <template v-if="openingcommission !== ''">
          <p class="text-info">
              Comisión por apertura del {{openingcommission}}%
          </p>
          <p class="amount small">
            {{ formatMoney(finalPay.commissionAmount || 0) }}
            <br />
            <small>(más IVA)</small>
          </p>
        </template>
        <p class="title">Tu pagarías:</p>

        <p class="text-info">
          {{ finalPay ? finalPay.totalPayments : 0 }} 
          {{ finalPay.totalPayments > 1 ? 'pagos' : 'pago' }} 
          {{ periodicityName }} de
        </p>

        <p class="amount">
          {{ formatMoney(finalPay ? finalPay.amount : 0) }}
        </p>

        <template v-if="productType === 'bullet' && finalPay.term > 1">
          <p class="text-info">
            1 pago de
          </p>

          <p class="amount">
            {{ formatMoney(finalPay.lastPayment) }}
          </p>
        </template>

      </div>
      <div class="info-financial">
        Interés anual {{ finalPay ? finalPay.interest : 0 }}%
      </div>
      <div class="info-financial" v-if="showCatValue">
        CAT {{ catValue }}%
      </div>
    </div>
    <div class="content-cat">
      <label class="message-cat" v-if="showCatValue">
        * El CAT es informativo
      </label>
    </div>
  </div>
</template>

<script>
import formats from '@/mixins/formats'
import { mapState } from 'vuex'

export default {
  mixins: [formats],
  props: {
    periodicity: {
      type: String,
      default: ''
    },
    showCat: {
      type: String,
      default: ''
    },
    productType: {
      type: String,
      default: ''
    },
    openingcommission: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('simulator', {
      finalPay: 'item'
    }),
    catValue () {
      return this.finalPay ? (this.finalPay.cat * 100).toFixed(2) : 0
    },
    showCatValue () {
      return this.showCat === 'true'
    },
    periodicityName (term) {
      switch (this.periodicity) {
        case 'W':
          return term !== 1 ? 'semanales' : 'semanal'
        case 'BW':
          return term !== 1 ? 'quincenales' : 'quincenal'
        case 'M':
          return term !== 1 ? 'mensuales' : 'mensual'
        default: 
          return ''
      }
    }
  }
}
</script>
