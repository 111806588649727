import { buildRequest } from './network'

export async function getSimulate ({ apikey, body }) {
  const request = buildRequest(apikey, '/simulador/', JSON.stringify(body))
  const response = await fetch(request)
  const { data } = await response.json()
  
  return data
}
export async function getCAT (values, guess) {
  const irrResult = function (values, dates, rate) {
    const r = rate + 1
    let result = values[0]
    for (let i = 1; i < values.length; i++) {
      result += values[i] / Math.pow(r, (dates[i] - dates[0]) / 365)
    }
    return result
  }

  const irrResultDeriv = function (values, dates, rate) {
    const r = rate + 1
    let result = 0
    for (let i = 1; i < values.length; i++) {
      const frac = (dates[i] - dates[0]) / 365
      result -= frac * values[i] / Math.pow(r, frac + 1)
    }
    return result
  }

  const dates = []
  let positive = false
  let negative = false
  for (var i = 0; i < values.length; i++) {
    dates[i] = (i === 0) ? 0 : dates[i - 1] + 365
    if (values[i] > 0) positive = true
    if (values[i] < 0) negative = true
  }

  if (!positive || !negative) return '#NUM!'
  guess = (typeof guess === 'undefined') ? 0.1 : guess
  let resultRate = guess

  const epsMax = 1e-10

  const iterMax = 50

  let newRate, epsRate, resultValue
  let iteration = 0
  let contLoop = true
  do {
    resultValue = irrResult(values, dates, resultRate)
    newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate)
    epsRate = Math.abs(newRate - resultRate)
    resultRate = newRate
    contLoop = (epsRate > epsMax) && (Math.abs(resultValue) > epsMax)
  } while (contLoop && (++iteration < iterMax))
  if (contLoop) return '#NUM!'

  return resultRate
}  
