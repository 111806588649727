import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './styles/global-styles.css'
import money from 'v-money'
import 'document-register-element/build/document-register-element';
import vueCustomElement from 'vue-custom-element'

Vue.config.productionTip = false
Vue.use(vueCustomElement)
Vue.use(money, {precision: 4})

App.store = store

Vue.customElement('intelligential-widget', App)
