export default {
  methods: {
    themeColor ({ title, input, rangesSlider, icon, focus, typography }) {
      document.documentElement.style.setProperty('--title--color', title || '#585858')
      document.documentElement.style.setProperty('--input--color', input || '#e4effa')
      document.documentElement.style.setProperty('--slider-thumb--color', rangesSlider || '#76b6ff')
      document.documentElement.style.setProperty('--icons--color', icon || '#1683ff')
      document.documentElement.style.setProperty('--focus-color', focus || '#459ed4')
      this.getTypography(typography)
    },
    getTypography (typography) {
      if (typography) {
        document.documentElement.style.setProperty('--widget--typography', typography)
      } else {
        document.documentElement.style.setProperty('--widget--typography', "Avenir", "Helvetica", "Arial", "sans-serif")
      }
    }
  }
}