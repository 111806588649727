<template>
  <div class="card-intelligential content-intelligential">
    <div class="content-elements">
      <div class="calculator-content">
        <h1 class="title-intelligential">Calcula tu crédito</h1>
        <p>
          Selecciona las características que mejor se adapten a tus necesidades para calcular tú crédito.
        </p>

        <calculator-component
          :maxAmount="maxamount"
          :minAmount="minamount"
          :maxTerm="maxterm"
          :minTerm="minterm"
          :interest="interest"
          :rangeAmount="rangeamount"
          :productType="producttype.toLowerCase()"
          @hdlOnChange="simulate"
          :showCat="showcat"
          :openingcommission="openingcommission"
        />
      </div>
      <div class="amount-content">
        <info-amount
          :periodicity="periodicity"
          :showCat="showcat"
          :productType="producttype.toLowerCase()"
          :openingcommission="openingcommission"
        />
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import theme from '@/mixins/theme'

export default {
  mixins: [theme],
  props: {
    maxamount: {
      type: Number,
      default: null
    },
    minamount: {
      type: Number,
      default: null
    },
    minterm: {
      type: Number,
      default: null
    },
    maxterm: {
      type: Number,
      default: null
    },
    interest: {
      type: Number,
      default: null
    },
    rangeamount: {
      type: Number,
      default: null
    },
    apikey: {
      type: String,
      default: null
    },
    titlecolor: {
      type: String,
      default: ''
    },
    inputcolor: {
      type: String,
      default: ''
    },
    rangeslidercolor: {
      type: String,
      default: ''
    },
    iconscolor: {
      type: String,
      default: ''
    },
    focuscolor: {
      type: String,
      default: ''
    },
    font: {
      type: String,
      default: ''
    },
    showcat: {
      type: String,
      default: ''
    },
    producttype: {
      type: String,
      default: ''
    },
    openingcommission: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.initComponent()
  },
  data () {
    return {
      periodicity: 'M'
    }
  },
  methods: {
    ...mapActions({
      getSimulate: 'simulator/getSimulate',
      simulateBulletPayment: 'simulator/simulateBulletPayment'
    }),
    async simulateSimplePayment (body) {
      this.periodicity = body.periodicity
      await this.getSimulate({ apikey: this.apikey, body: body })
    },
    async simulateBulletPayments (body) {
      await this.simulateBulletPayment(body)
    },
    simulate (body) {
      if (this.producttype === 'bullet') {
        this.simulateBulletPayments(body)
      } else {
        this.simulateSimplePayment(body)
      }
    },
    initComponent () {
      this.themeColor({
        title: this.titlecolor,
        input: this.inputcolor,
        rangesSlider: this.rangeslidercolor,
        icon: this.iconscolor,
        focus: this.focuscolor,
        typography: this.font
      })

      if (this.producttype === 'bullet') {
        this.simulateBulletPayments({
          interest: this.interest,
          amount: this.minamount,
          term: this.minterm,
          commission: this.openingcommission
        })
      } else {
        this.simulateSimplePayment({
          interest: this.interest,
          periodicity: this.periodicity,
          term: this.minterm,
          amount: this.minamount,
          commission: this.openingcommission
        })
      } 
    }
  },
  components: {
    CalculatorComponent: () => import('@/components/CalculatorComponent'),
    InfoAmount: () => import('@/components/InfoAmount')
  }
}
</script>
