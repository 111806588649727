import Vue from 'vue'
import Vuex from 'vuex'
import { frequencyStore } from './simulator'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    simulator: frequencyStore
  }
})
