const url = process.env.VUE_APP_API

export function buildRequest(apikey, uri, body) {

  return new Request(`${url}${uri}`, {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      'domain': apikey || process.env.TOKEN_DOMAIN
    },
    body: body
  })
}
